<template>
  <div>
    <div class="bg-blue-700 text-gray-200 rounded-t p-4 py-6">
      <div class="uppercase text-xs font-bold"></div>
      <h1 class="text-xl">Datenschutzerklärung</h1>
    </div>
    <div class="p-4">
      <p id="dsg-general-intro">
        Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck
        der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“)
        im Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres
        Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und
        Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media
        Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im
        Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“
        oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
        Datenschutzgrundverordnung (DSGVO).
      </p>

      <h3 id="dsg-general-controller">Verantwortlicher</h3>
      <p>
        <span class="tsmcontroller"
          >SpriPlan GmbH &amp; Co. KG<br />Erhard-Stangl-Ring 14<br />84435
          Lengdorf<br />Deutschland</span
        ><br />

        E-Mail: info@spriplan.de<br />Geschäftsführer: Dipl.-Ing. (FH) Markus
        Hösl<br />
        Impressum unter http://spriplan.de/
      </p>
      <h3 id="dsg-general-datatype">Arten der verarbeiteten Daten</h3>
      <p></p>
      <ul>
        <li>Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).</li>
        <li>
          Kontaktdaten (z.B., E-Mail, Telefonnummern). - Inhaltsdaten (z.B.,
          Texteingaben, Fotografien, Videos). - Nutzungsdaten (z.B., besuchte
          Webseiten, Interesse an Inhalten, Zugriffszeiten).
        </li>
        <li>
          Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).
        </li>
      </ul>
      <h3 id="dsg-general-datasubjects">Kategorien betroffener Personen</h3>
      <p>
        Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die
        betroffenen Personen zusammenfassend auch als „Nutzer“).
      </p>
      <h3 id="dsg-general-purpose">Zweck der Verarbeitung</h3>
      <ul>
        <li>
          Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
          Inhalte.
        </li>
        <li>Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.</li>
        <li>Sicherheitsmaßnahmen.</li>
        <li>Reichweitenmessung/Marketing</li>
      </ul>
      <h3 id="dsg-general-terms">Verwendete Begrifflichkeiten</h3>
      <p>
        „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
        identifizierte oder identifizierbare natürliche Person (im Folgenden
        „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
        Person angesehen, die direkt oder indirekt, insbesondere mittels
        Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
        Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder
        mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck
        der physischen, physiologischen, genetischen, psychischen,
        wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen
        Person sind. „Verarbeitung“ ist jeder mit oder ohne Hilfe
        automatisierter Verfahren ausgeführte Vorgang oder jede solche
        Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff
        reicht weit und umfasst praktisch jeden Umgang mit Daten.
        „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer
        Weise, dass die personenbezogenen Daten ohne Hinzuziehung zusätzlicher
        Informationen nicht mehr einer spezifischen betroffenen Person
        zugeordnet werden können, sofern diese zusätzlichen Informationen
        gesondert aufbewahrt werden und technischen und organisatorischen
        Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen
        Daten nicht einer identifizierten oder identifizierbaren natürlichen
        Person zugewiesen werden. „Profiling“ jede Art der automatisierten
        Verarbeitung personenbezogener Daten, die darin besteht, dass diese
        personenbezogenen Daten verwendet werden, um bestimmte persönliche
        Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
        insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage,
        Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit,
        Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu
        analysieren oder vorherzusagen. Als „Verantwortlicher“ wird die
        natürliche oder juristische Person, Behörde, Einrichtung oder andere
        Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel
        der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
        „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde,
        Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag
        des Verantwortlichen verarbeitet.
      </p>
      <h3 id="dsg-general-legalbasis">Maßgebliche Rechtsgrundlagen</h3>
      <p>
        Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen
        unserer Datenverarbeitungen mit. Für Nutzer aus dem Geltungsbereich der
        Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern
        die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird,
        Folgendes: Die Rechtsgrundlage für die Einholung von Einwilligungen ist
        Art. 6 Abs. 1 lit. a und Art. 7 DSGVO; Die Rechtsgrundlage für die
        Verarbeitung zur Erfüllung unserer Leistungen und Durchführung
        vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs.
        1 lit. b DSGVO; Die Rechtsgrundlage für die Verarbeitung zur Erfüllung
        unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO; Für
        den Fall, dass lebenswichtige Interessen der betroffenen Person oder
        einer anderen natürlichen Person eine Verarbeitung personenbezogener
        Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
        Rechtsgrundlage. Die Rechtsgrundlage für die erforderliche Verarbeitung
        zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder
        in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
        übertragen wurde ist Art. 6 Abs. 1 lit. e DSGVO. Die Rechtsgrundlage für
        die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6
        Abs. 1 lit. f DSGVO. Die Verarbeitung von Daten zu anderen Zwecken als
        denen, zu denen sie erhoben wurden, bestimmt sich nach den Vorgaben des
        Art 6 Abs. 4 DSGVO. Die Verarbeitung von besonderen Kategorien von Daten
        (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich nach den Vorgaben des
        Art. 9 Abs. 2 DSGVO.
      </p>
      <h3 id="dsg-general-securitymeasures">Sicherheitsmaßnahmen</h3>
      <p>
        Wir treffen nach Maßgabe der gesetzlichen Vorgabenunter Berücksichtigung
        des Stands der Technik, der Implementierungskosten und der Art, des
        Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
        unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos
        für die Rechte und Freiheiten natürlicher Personen, geeignete technische
        und organisatorische Maßnahmen, um ein dem Risiko angemessenes
        Schutzniveau zu gewährleisten. Zu den Maßnahmen gehören insbesondere die
        Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten
        durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie
        betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der
        Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
        eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von
        Daten und Reaktion auf Gefährdung der Daten gewährleisten. Ferner
        berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
        Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren,
        entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und
        durch datenschutzfreundliche Voreinstellungen.
      </p>
      <h3 id="dsg-general-coprocessing">
        Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und
        Dritten
      </h3>
      <p>
        Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
        Personen und Unternehmen (Auftragsverarbeitern, gemeinsam
        Verantwortlichen oder Dritten) offenbaren, sie an diese übermitteln oder
        ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf
        Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der
        Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserfüllung
        erforderlich ist), Nutzer eingewilligt haben, eine rechtliche
        Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten
        Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).
        Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe
        offenbaren, übermitteln oder ihnen sonst den Zugriff gewähren, erfolgt
        dies insbesondere zu administrativen Zwecken als berechtigtes Interesse
        und darüberhinausgehend auf einer den gesetzlichen Vorgaben
        entsprechenden Grundlage.
      </p>
      <h3 id="dsg-general-thirdparty">Übermittlungen in Drittländer</h3>
      <p>
        Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
        Union (EU), des Europäischen Wirtschaftsraums (EWR) oder der Schweizer
        Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme
        von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an
        andere Personen oder Unternehmen geschieht, erfolgt dies nur, wenn es
        zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer
        Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf
        Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich
        ausdrücklicher Einwilligung oder vertraglich erforderlicher
        Übermittlung, verarbeiten oder lassen wir die Daten nur in Drittländern
        mit einem anerkannten Datenschutzniveau, zu denen die unter dem
        "Privacy-Shield" zertifizierten US-Verarbeiter gehören oder auf
        Grundlage besonderer Garantien, wie z.B. vertraglicher Verpflichtung
        durch sogenannte Standardschutzklauseln der EU-Kommission, dem Vorliegen
        von Zertifizierungen oder verbindlichen internen Datenschutzvorschriften
        verarbeiten (Art. 44 bis 49 DSGVO,
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu_de"
          target="blank"
          >Informationsseite der EU-Kommission</a
        >).
      </p>
      <h3 id="dsg-general-rightssubject">Rechte der betroffenen Personen</h3>
      <p>
        Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob
        betreffende Daten verarbeitet werden und auf Auskunft über diese Daten
        sowie auf weitere Informationen und Kopie der Daten entsprechend den
        gesetzlichen Vorgaben. Sie haben entsprechend. den gesetzlichen Vorgaben
        das Recht, die Vervollständigung der Sie betreffenden Daten oder die
        Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen. Sie
        haben nach Maßgabe der gesetzlichen Vorgaben das Recht zu verlangen,
        dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ
        nach Maßgabe der gesetzlichen Vorgaben eine Einschränkung der
        Verarbeitung der Daten zu verlangen. Sie haben das Recht zu verlangen,
        dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach
        Maßgabe der gesetzlichen Vorgaben zu erhalten und deren Übermittlung an
        andere Verantwortliche zu fordern. Sie haben ferner nach Maßgabe der
        gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zuständigen
        Aufsichtsbehörde einzureichen.
      </p>
      <h3 id="dsg-general-revokeconsent">Widerrufsrecht</h3>
      <p>
        Sie haben das Recht, erteilte Einwilligungen mit Wirkung für die Zukunft
        zu widerrufen.
      </p>
      <h3 id="dsg-general-object">Widerspruchsrecht</h3>
      <p>
        <strong
          >Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach
          Maßgabe der gesetzlichen Vorgaben jederzeit widersprechen. Der
          Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
          Direktwerbung erfolgen.</strong
        >
      </p>
      <h3 id="dsg-general-cookies">
        Cookies und Widerspruchsrecht bei Direktwerbung
      </h3>
      <p>
        Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der
        Nutzer gespeichert werden. Innerhalb der Cookies können unterschiedliche
        Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu
        einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während
        oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu
        speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder
        „transiente Cookies“, werden Cookies bezeichnet, die gelöscht werden,
        nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser
        schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs
        in einem Onlineshop oder ein Login-Status gespeichert werden. Als
        „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach
        dem Schließen des Browsers gespeichert bleiben. So kann z.B. der
        Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren
        Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen
        der Nutzer gespeichert werden, die für Reichweitenmessung oder
        Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden
        Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen,
        der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es
        nur dessen Cookies sind spricht man von „First-Party Cookies“). Wir
        können temporäre und permanente Cookies einsetzen und klären hierüber im
        Rahmen unserer Datenschutzerklärung auf. Sofern wir die Nutzer um eine
        Einwilligung in den Einsatz von Cookies bitten (z.B. im Rahmen einer
        Cookie-Einwilligung), ist die Rechtsgrundlage dieser Verarbeitung Art. 6
        Abs. 1 lit. a. DSGVO. Ansonsten werden die personenbezogenen Cookies der
        Nutzer entsprechend den nachfolgenden Erläuterungen im Rahmen dieser
        Datenschutzerklärung auf Grundlage unserer berechtigten Interessen (d.h.
        Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb
        unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) oder
        sofern der Einsatz von Cookies zur Erbringung unserer vertragsbezogenen
        Leistungen erforderlich ist, gem. Art. 6 Abs. 1 lit. b. DSGVO, bzw.
        sofern der Einsatz von Cookies für die Wahrnehmung einer Aufgabe, die im
        öffentlichen Interesse liegt erforderlich ist oder in Ausübung
        öffentlicher Gewalt erfolgt, gem. Art. 6 Abs. 1 lit. e. DSGVO,
        verarbeitet. Falls die Nutzer nicht möchten, dass Cookies auf ihrem
        Rechner gespeichert werden, werden sie gebeten die entsprechende Option
        in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte
        Cookies können in den Systemeinstellungen des Browsers gelöscht werden.
        Der Ausschluss von Cookies kann zu Funktionseinschränkungen dieses
        Onlineangebotes führen. Ein genereller Widerspruch gegen den Einsatz der
        zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer
        Vielzahl der Dienste, vor allem im Fall des Trackings, über die
        US-amerikanische Seite
        <a href="http://www.aboutads.info/choices/"
          >http://www.aboutads.info/choices/</a
        >
        oder die EU-Seite
        <a href="http://www.youronlinechoices.com/"
          >http://www.youronlinechoices.com/</a
        >
        erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels
        deren Abschaltung in den Einstellungen des Browsers erreicht werden.
        Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen
        dieses Onlineangebotes genutzt werden können.
      </p>
      <h3 id="dsg-general-erasure">Löschung von Daten</h3>
      <p>
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen
        Vorgaben gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht
        im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die
        bei uns gespeicherten Daten gelöscht, sobald sie für ihre
        Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
        gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
        nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
        Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h.
        die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das
        gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen
        aufbewahrt werden müssen.
      </p>
      <h3 id="dsg-general-changes">
        Änderungen und Aktualisierungen der Datenschutzerklärung
      </h3>
      <p>
        Wir bitten Sie sich regelmäßig über den Inhalt unserer
        Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung
        an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen
        dies erforderlich machen. Wir informieren Sie, sobald durch die
        Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder
        eine sonstige individuelle Benachrichtigung erforderlich wird.
      </p>
      <h3 id="dsg-commercialpurpose">Geschäftsbezogene Verarbeitung</h3>
      <p>
        <span class="ts-muster-content"
          >Zusätzlich verarbeiten wir<br />Vertragsdaten (z.B.,
          Vertragsgegenstand, Laufzeit, Kundenkategorie).<br />- Zahlungsdaten
          (z.B., Bankverbindung, Zahlungshistorie) von unseren Kunden,
          Interessenten und Geschäftspartner zwecks Erbringung vertraglicher
          Leistungen, Service und Kundenpflege, Marketing, Werbung und
          Marktforschung.</span
        >
      </p>
      <h3 id="dsg-services-contractualservices">Vertragliche Leistungen</h3>
      <p>
        <span class="ts-muster-content"
          >Wir verarbeiten die Daten unserer Vertragspartner und Interessenten
          sowie anderer Auftraggeber, Kunden, Mandanten, Klienten oder
          Vertragspartner (einheitlich bezeichnet als „Vertragspartner“)
          entsprechend Art. 6 Abs. 1 lit. b. DSGVO, um ihnen gegenüber unsere
          vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die
          hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und die
          Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem
          zugrundeliegenden Vertragsverhältnis.</span
        >
      </p>

      <p>
        Zu den verarbeiteten Daten gehören die Stammdaten unserer
        Vertragspartner (z.B., Namen und Adressen), Kontaktdaten (z.B.
        E-Mailadressen und Telefonnummern) sowie Vertragsdaten (z.B., in
        Anspruch genommene Leistungen, Vertragsinhalte, vertragliche
        Kommunikation, Namen von Kontaktpersonen) und Zahlungsdaten (z.B.,
        Bankverbindungen, Zahlungshistorie). Besondere Kategorien
        personenbezogener Daten verarbeiten wir grundsätzlich nicht, außer wenn
        diese Bestandteile einer beauftragten oder vertragsgemäßen Verarbeitung
        sind. Wir verarbeiten Daten, die zur Begründung und Erfüllung der
        vertraglichen Leistungen erforderlich sind und weisen auf die
        Erforderlichkeit ihrer Angabe, sofern diese für die Vertragspartner
        nicht evident ist, hin. Eine Offenlegung an externe Personen oder
        Unternehmen erfolgt nur, wenn sie im Rahmen eines Vertrags erforderlich
        ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags überlassenen
        Daten, handeln wir entsprechend den Weisungen der Auftraggeber sowie der
        gesetzlichen Vorgaben. Im Rahmen der Inanspruchnahme unserer
        Onlinedienste, können wir die IP-Adresse und den Zeitpunkt der
        jeweiligen Nutzerhandlung speichern. Die Speicherung erfolgt auf
        Grundlage unserer berechtigten Interessen, als auch der Interessen der
        Nutzer am Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine
        Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, außer sie
        ist zur Verfolgung unserer Ansprüche gem. Art. 6 Abs. 1 lit. f. DSGVO
        erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung gem.
        Art. 6 Abs. 1 lit. c. DSGVO. Die Löschung der Daten erfolgt, wenn die
        Daten zur Erfüllung vertraglicher oder gesetzlicher Fürsorgepflichten
        sowie für den Umgang mit etwaigen Gewährleistungs- und vergleichbaren
        Pflichten nicht mehr erforderlich sind, wobei die Erforderlichkeit der
        Aufbewahrung der Daten alle drei Jahre überprüft wird; im Übrigen gelten
        die gesetzlichen Aufbewahrungspflichten.
      </p>
      <h3 id="dsg-administration">
        Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung
      </h3>
      <p>
        <span class="ts-muster-content"
          >Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie
          Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der
          gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten
          wir dieselben Daten, die wir im Rahmen der Erbringung unserer
          vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen sind
          Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der
          Verarbeitung sind Kunden, Interessenten, Geschäftspartner und
          Websitebesucher betroffen. Der Zweck und unser Interesse an der
          Verarbeitung liegt in der Administration, Finanzbuchhaltung,
          Büroorganisation, Archivierung von Daten, also Aufgaben die der
          Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung unserer
          Aufgaben und Erbringung unserer Leistungen dienen. Die Löschung der
          Daten im Hinblick auf vertragliche Leistungen und die vertragliche
          Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten
          genannten Angaben.</span
        >
      </p>

      <p>
        Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung,
        Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere
        Gebührenstellen und Zahlungsdienstleister. Ferner speichern wir auf
        Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu
        Lieferanten, Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks
        späterer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen
        Daten, speichern wir grundsätzlich dauerhaft.
      </p>
      <h3 id="dsg-businessanalysis">
        Betriebswirtschaftliche Analysen und Marktforschung
      </h3>
      <p>
        <span class="ts-muster-content"
          >Um unser Geschäft wirtschaftlich betreiben, Markttendenzen, Wünsche
          der Vertragspartner und Nutzer erkennen zu können, analysieren wir die
          uns vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen,
          etc. Wir verarbeiten dabei Bestandsdaten, Kommunikationsdaten,
          Vertragsdaten, Zahlungsdaten, Nutzungsdaten, Metadaten auf Grundlage
          des Art. 6 Abs. 1 lit. f. DSGVO, wobei zu den betroffenen Personen
          Vertragspartner, Interessenten, Kunden, Besucher und Nutzer unseres
          Onlineangebotes gehören.</span
        >
      </p>

      <p>
        Die Analysen erfolgen zum Zweck betriebswirtschaftlicher Auswertungen,
        des Marketings und der Marktforschung. Dabei können wir die Profile der
        registrierten Nutzer mit Angaben, z.B. zu deren in Anspruch genommenen
        Leistungen, berücksichtigen. Die Analysen dienen uns zur Steigerung der
        Nutzerfreundlichkeit, der Optimierung unseres Angebotes und der
        Betriebswirtschaftlichkeit. Die Analysen dienen alleine uns und werden
        nicht extern offenbart, sofern es sich nicht um anonyme Analysen mit
        zusammengefassten Werten handelt. Sofern diese Analysen oder Profile
        personenbezogen sind, werden sie mit Kündigung der Nutzer gelöscht oder
        anonymisiert, sonst nach zwei Jahren ab Vertragsschluss. Im Übrigen
        werden die gesamtbetriebswirtschaftlichen Analysen und allgemeine
        Tendenzbestimmungen nach Möglichkeit anonym erstellt.
      </p>
      <h3 id="dsg-google-cloud">Google Cloud-Dienste</h3>
      <p>
        <span class="ts-muster-content"
          >Wir nutzen die von Google angebotene Cloud und die
          Cloud-Softwaredienste (sog. Software as a Service, z.B. Google Suite)
          für die folgenden Zwecke: Dokumentenspeicherung und Verwaltung,
          Kalenderverwaltung, E-Mail-Versand, Tabellenkalkulationen und
          Präsentationen, Austausch von Dokumenten, Inhalten und Informationen
          mit bestimmten Empfängern oder Veröffentlichung von Webseiten,
          Formularen oder sonstigen Inhalten und Informationen sowie Chats und
          Teilnahme an Audio- und Videokonferenzen.</span
        >
      </p>

      <p>
        Hierbei werden die personenbezogenen Daten der Nutzer verarbeitet,
        soweit diese Bestandteil der innerhalb der beschriebenen Dienste
        verarbeiteten Dokumente und Inhalte werden oder Teil von
        Kommunikationsvorgängen sind. Hierzu können z.B. Stammdaten und
        Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen, sonstigen
        Prozessen und deren Inhalte gehören. Google verarbeitet ferner
        Nutzungsdaten und Metadaten, die von Google zu Sicherheitszwecken und
        Serviceoptimierung verwendet werden. Im Rahmen der Nutzung öffentlich
        zugängliche Dokumente, Webseiten oder sonstige Inhalte, kann Google
        Cookies auf den Rechner der Nutzer für Zwecke der Webanalyse oder um
        sich Einstellungen der Nutzer zu merken, speichern. Wir nutzen die
        Google Cloud-Dienste auf Grundlage unserer berechtigten Interessen gem.
        Art. 6 Abs. 1 lit. f DSGVO an effizienten und sicheren Verwaltungs- und
        Zusammenarbeitsprozessen. Ferner erfolgt die Verarbeitung auf Grundlage
        eines Auftragsverarbeitungsvertrages mit Google (<a
          href="https://cloud.google.com/terms/data-processing-terms"
          target="_blank"
          >https://cloud.google.com/terms/data-processing-terms</a
        >). Weitere Hinweise erhalten Sie in der Datenschutzerklärung von Google
        (<a href="https://www.google.com/policies/privacy" target="_blank"
          >https://www.google.com/policies/privacy</a
        >) und den Sicherheitshinweisen zu Google Cloud-Diensten (<a
          href="https://cloud.google.com/security/privacy/"
          target="_blank"
          >https://cloud.google.com/security/privacy/</a
        >). Sie können der Verarbeitung Ihrer Daten in der Google Cloud uns
        gegenüber entsprechend den gesetzlichen Vorgaben widersprechen. Im
        Übrigen bestimmt sich die Löschung der Daten innerhalb von Googles
        Cloud-Diensten nach den übrigen Verarbeitungsprozessen in deren Rahmen
        die Daten verarbeitet werden (z.B., Löschung für Vertragszwecke nicht
        mehr erforderlicher oder Speicherung für Zwecke der Besteuerung
        erforderlicher Daten). Die Google Cloud-Dienste werden von Google Irland
        Limited angeboten. Soweit eine Übermittlung in die USA stattfindet,
        verweisen wir auf die Zertifizierung von Google USA unter dem Privacy
        Shield(<a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&amp;status=Aktive"
          target="_blank"
          >https://www.privacyshield.gov/participant?id=a2zt0000000000001L5AAI&amp;status=Aktive</a
        >) und Standardschutzklauseln (<a
          href="https://cloud.google.com/terms/data-processing-terms"
          target="_blank"
          >https://cloud.google.com/terms/data-processing-terms</a
        >).
      </p>
      <h3 id="dsg-microsoft-cloud">Microsoft Cloud-Dienste</h3>
      <p>
        <span class="ts-muster-content"
          >Wir nutzen die von Microsoft angebotene Cloud und die
          Cloud-Softwaredienste (sog. Software as a Service, z.B. Microsoft
          Office) für die folgenden Zwecke: Dokumentenspeicherung und
          Verwaltung, Kalenderverwaltung, E-Mail-Versand, Tabellenkalkulationen
          und Präsentationen, Austausch von Dokumenten, Inhalten und
          Informationen mit bestimmten Empfängern oder Veröffentlichung von
          Webseiten, Formularen oder sonstigen Inhalten und Informationen sowie
          Chats und Teilnahme an Audio- und Videokonferenzen.</span
        >
      </p>

      <p>
        Hierbei werden die personenbezogenen Daten der Nutzer verarbeitet,
        soweit diese Bestandteil der innerhalb der beschriebenen Dienste
        verarbeiteten Dokumente und Inhalte werden oder Teil von
        Kommunikationsvorgängen sind. Hierzu können z.B. Stammdaten und
        Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen, sonstigen
        Prozessen und deren Inhalte gehören. Microsoft verarbeitet ferner
        Nutzungsdaten und Metadaten, die von Microsoft zu Sicherheitszwecken und
        Serviceoptimierung verwendet werden. Im Rahmen der Nutzung öffentlich
        zugängliche Dokumente, Webseiten oder sonstige Inhalte, kann Microsoft
        Cookies auf den Rechner der Nutzer für Zwecke der Webanalyse oder um
        sich Einstellungen der Nutzer zu merken, speichern. Wir nutzen die
        Microsoft Cloud-Dienste auf Grundlage unserer berechtigten Interessen
        gem. Art. 6 Abs. 1 lit. f DSGVO an effizienten und sicheren Verwaltungs-
        und Zusammenarbeitsprozessen. Ferner erfolgt die Verarbeitung auf
        Grundlage eines Auftragsverarbeitungsvertrages mit Microsoft. Weitere
        Hinweise erhalten Sie in der Datenschutzerklärung von Microsoft (<a
          href="https://privacy.microsoft.com/de-de/privacystatement"
          target="_blank"
          >https://privacy.microsoft.com/de-de/privacystatement</a
        >) und den Sicherheitshinweisen zu Microsoft Cloud-Diensten (<a
          href="https://www.microsoft.com/de-de/trustcenter"
          target="_blank"
          >https://www.microsoft.com/de-de/trustcenter</a
        >). Sie können der Verarbeitung Ihrer Daten in der Microsoft Cloud uns
        gegenüber entsprechend den gesetzlichen Vorgaben widersprechen. Im
        Übrigen bestimmt sich die Löschung der Daten innerhalb von Microsofts
        Cloud-Diensten nach den übrigen Verarbeitungsprozessen in deren Rahmen
        die Daten verarbeitet werden (z.B., Löschung für Vertragszwecke nicht
        mehr erforderlicher oder Speicherung für Zwecke der Besteuerung
        erforderlicher Daten). Die Microsoft Cloud-Dienste werden von Microsoft
        Corporation, One Microsoft Way, Redmond, WA 98052-6399 USA angeboten.
        Soweit eine Verarbeitung von Daten in die USA stattfindet, verweisen wir
        auf die Zertifizierung von Microsoft unter dem Privacy Shield (<a
          href="https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK&amp;status=Active"
          target="_blank"
          >https://www.privacyshield.gov/participant?id=a2zt0000000KzNaAAK&amp;status=Active</a
        >).
      </p>
      <h3 id="dsg-wordpress-emojis">Abruf von Emojis und Smilies</h3>
      <p>
        <span class="ts-muster-content"
          >Innerhalb unseres WordPress-Blogs werden grafische Emojis (bzw.
          Smilies), d.h. kleine grafische Dateien, die Gefühle ausdrücken,
          eingesetzt, die von externen Servern bezogen werden. Hierbei erheben
          die Anbieter der Server, die IP-Adressen der Nutzer. Dies ist
          notwendig, damit die Emojie-Dateien an die Browser der Nutzer
          übermittelt werden können. Der Emojie-Service wird von der Automattic
          Inc., 60 29th Street #343, San Francisco, CA 94110, USA, angeboten.
          Datenschutzhinweise von Automattic:
          <a href="https://automattic.com/privacy/" target="_blank"
            >https://automattic.com/privacy/</a
          >. Die verwendeten Server-Domains sind s.w.org und twemoji.maxcdn.com,
          wobei es sich unseres Wissens nach um sog. Content-Delivery-Networks
          handelt, also Server, die lediglich einer schnellen und sicheren
          Übermittlung der Dateien dienen und die personenbezogenen Daten der
          Nutzer nach der Übermittlung gelöscht werden.</span
        >
      </p>

      <p>
        Die Nutzung der Emojis erfolgt auf Grundlage unserer berechtigten
        Interessen, d.h. Interesse an einer attraktiven Gestaltung unseres
        Onlineangebotes gem. Art. 6 Abs. 1 lit. f. DSGVO.
      </p>
      <h3 id="dsg-contact">Kontaktaufnahme</h3>
      <p>
        <span class="ts-muster-content"
          >Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
          Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur
          Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1
          lit. b. (im Rahmen vertraglicher-/vorvertraglicher Beziehungen), Art.
          6 Abs. 1 lit. f. (andere Anfragen) DSGVO verarbeitet.. Die Angaben der
          Nutzer können in einem Customer-Relationship-Management System ("CRM
          System") oder vergleichbarer Anfragenorganisation gespeichert
          werden.</span
        >
      </p>

      <p>
        Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir
        überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die
        gesetzlichen Archivierungspflichten.
      </p>
      <h3 id="dsg-hostingprovider">Hosting und E-Mail-Versand</h3>
      <p>
        <span class="ts-muster-content"
          >Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
          Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
          Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
          Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie
          technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
          Onlineangebotes einsetzen.</span
        >
      </p>

      <p>
        Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
        Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
        Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
        Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer
        effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes
        gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
        Auftragsverarbeitungsvertrag).
      </p>
      <h3 id="dsg-logfiles">Erhebung von Zugriffsdaten und Logfiles</h3>
      <p>
        <span class="ts-muster-content"
          >Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
          berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
          über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
          (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
          abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene
          Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst
          Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor
          besuchte Seite), IP-Adresse und der anfragende Provider.</span
        >
      </p>

      <p>
        Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung
        von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7
        Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung
        zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
        jeweiligen Vorfalls von der Löschung ausgenommen.
      </p>
      <h3 id="dsg-thirdparty-googlefonts">Google Fonts</h3>
      <p>
        <span class="ts-muster-content"
          >Wir binden die Schriftarten ("Google Fonts") des Anbieters Google
          Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland, ein.
          Nach Angaben von Google werden die Daten der Nutzer allein zu Zwecken
          der Darstellung der Schriftarten im Browser der Nutzer verwendet. Die
          Einbindung erfolgt auf Grundlage unserer berechtigten Interessen an
          einer technisch sicheren, wartungsfreien und effizienten Nutzung von
          Schriftarten, deren einheitlicher Darstellung sowie Berücksichtigung
          möglicher lizenzrechtlicher Restriktionen für deren Einbindung.
          Datenschutzerklärung:
          <a href="https://www.google.com/policies/privacy/" target="_blank"
            >https://www.google.com/policies/privacy/</a
          >.</span
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style scoped>
p {
  margin: 0.5em 0;
}

h3 {
  font-weight: bold;
  font-size: 1.4em;
}
</style>